import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell primer cònic i després aplanat amb els marges estriats i de 5-10 cm de diàmetre, de color marró clar o marró grisós. Aquest està cobert al principi per petites esquames blanques concèntriques que són els restes del vel general, que es separen fàcilment per les gotes de pluja o amb els dits. Davall el capell té làmines blanques, fines i apretades, lliures del peu. El peu és cilíndric o lleugerament engruixut a la base, de 6-12 x 0,5-1,5 cm, llis, blanc i provist d’un anell membranós, prim i generalment molt regular, blanc i quasi gens o gens estriat per la cara superior. Les espores són blanques en massa, el·líptiques, de 10 x 8 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      